var columns = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'usernamme',
  // width: '14%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'user_type_str',
  key: 'user_type_str',
  // width: '5%',
  scopedSlots: {
    customRender: 'usertype'
  }
},
// {
//     title: '商家等级',
//     dataIndex: 'level_name',
//     key: 'level_name',
//     // width: '5%',
//     scopedSlots: {
//         customRender: 'level'
//     }
// },

{
  title: '商家邮箱',
  dataIndex: 'new_email',
  key: 'new_email',
  // width: '10%',
  scopedSlots: {
    customRender: 'email'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '事项',
  dataIndex: 'saletype',
  key: 'saletype',
  // width: '10%',
  scopedSlots: {
    customRender: 'saletype'
  }
}, {
  title: '申请时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '5%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  width: '100',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var columnsed = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'username',
  // width: '14%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'user_type_str',
  key: 'user_type_str',
  // width: '10%',
  scopedSlots: {
    customRender: 'usertype'
  }
},
// {
//     title: '商家等级',
//     dataIndex: 'level_name',
//     key: 'level_name',
//     // width: '10%',
//     scopedSlots: {
//         customRender: 'level'
//     }
// },

{
  title: '商家邮箱',
  dataIndex: 'new_email',
  key: 'new_email',
  // width: '10%',
  scopedSlots: {
    customRender: 'email'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '事项',
  dataIndex: 'saletype',
  key: 'saletype',
  // width: '10%',
  scopedSlots: {
    customRender: 'saletype'
  }
}, {
  title: '申请时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '10%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '审核时间',
  dataIndex: 'checktime',
  key: 'checktime',
  // width: '10%',
  scopedSlots: {
    customRender: 'checktime'
  }
}, {
  title: '审核人',
  dataIndex: 'operator_name',
  key: 'operator_name',
  // width: '5%',
  scopedSlots: {
    customRender: 'operator_name'
  }
}, {
  title: '审核结果',
  dataIndex: 'result_status_str',
  key: 'result_status_str',
  fixed: "right",
  width: '100',
  scopedSlots: {
    customRender: 'result_status_str'
  }
}];
export { columns, columnsed };